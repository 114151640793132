export default {
  state: {
    user: null,
  },
  mutations: {
    SET_USER(state, data) {
      state.user = data
    },
  },
  getters: {
    user(state) {
      return state.user
    },
    userName(state) {
      if (state.user) return state.user.name
    },
    isLoggedIn(state) {
      return state.user !== null
    },
  },
  actions: {
    updateUser({ commit }, { userId, email, displayName }) {
      const user = {
        id: userId,
        email: email,
        name: displayName,
      }
      commit('SET_USER', user)
    },
    logoutUser({ commit }) {
      commit('SET_USER', null)
    },
  },
}
