import { itemsHTTP } from '@/services/http'

export default {
  state: {
    items: null,
  },
  mutations: {
    SET_ITEMS(state, data) {
      state.items = data
    },
    ADD_ITEM(state, data) {
      state.items.push(data)
    },
    UPDATE_ITEMS(state, data) {
      state.items = state.items.map(stateItem => {
        return data.find(updateItem => updateItem.id == stateItem.id) || stateItem
      })
    },
    REMOVE_ITEM(state, data) {
      state.items = state.items.filter(item => item.id !== data)
    },
  },
  actions: {
    getItems: async ({ commit }) => {
      const { data } = await itemsHTTP.get('get-items/')
      commit('SET_ITEMS', data)
      return data
    },
    getItemsByStep: async ({ commit }, payload) => {
      const { data } = await itemsHTTP.get(`get-items/?step=${payload}`)
      commit('SET_ITEMS', data)
      return data
    },
    createItem: async ({ commit }, payload) => {
      const { data } = await itemsHTTP.post('create-item', payload)
      commit('ADD_ITEM', data)
      return data
    },
    // eslint-disable-next-line no-unused-vars
    updateItem: async ({ commit }, payload) => {
      const { data } = await itemsHTTP.patch('update-item', payload)
      return data
    },
    updateItems: async ({ commit }, payload) => {
      await itemsHTTP.patch('update-items', payload)
      commit('UPDATE_ITEMS', payload)
    },
    deleteItem: async ({ commit }, payload) => {
      await itemsHTTP.get(`delete-item?id=${payload}`)
      commit('REMOVE_ITEM', payload)
    },
  },
  getters: {
    items(state) {
      if (state.items) return state.items.sort((a, b) => a.index - b.index)
    },
    itemsBySubStep: state => subStep => {
      let items
      if (state.items) items = state.items.filter(item => item.subStep === subStep)
      items = items.sort((a, b) => a.index - b.index)
      return items
    },
  },
}
