import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import calculator from './calculator'
import items from './items'
import user from './user'
import welcome from './welcome'
import projects from './projects'
import subSteps from './subSteps'
import steps from './steps'
import zips from './zips'

Vue.use(Vuex)

const vuexPersistantState = new createPersistedState({
  key: 'montocph',
  storage: window.sessionStorage,
})

const store = new Vuex.Store({
  modules: {
    calculator,
    items,
    user,
    welcome,
    projects,
    subSteps,
    steps,
    zips,
  },
  plugins: [vuexPersistantState],
})

export default store
