<template>
  <div>
    <router-link to="/" class="logo" tag="img" :src="require(`@/assets/logo.svg`)"></router-link>
    <nav class="nav">
      <router-link to="/" exact>Referencer</router-link>
      <router-link to="/contact">Kontakt</router-link>
      <router-link to="/calculator/first-phase/1">Beregner</router-link>
      <router-link to="/admin/calculator-builder">Login</router-link>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['steps']),
    totalSteps() {
      return this.steps.length
    },
  },
}
</script>

<style lang="scss" scoped>
.logo {
  width: 60px;
  margin-left: 1.25rem;
  margin-top: 1.25rem;
  cursor: pointer;
}
.nav {
  padding: 1.25rem;
  display: grid;
  gap: 0.25rem;
  grid-template-columns: repeat(4, auto);
  max-width: var(--content-max-width);
  margin: auto;
  font-size: 0.935rem;
}
.nav > a {
  color: #999999;
  text-decoration: none;
  &:hover,
  &.router-link-active {
    color: #333333;
  }
}
@media screen and (min-width: 600px) {
  .logo {
    position: absolute;
    width: 90px;
    top: 4rem;
    transform: translateY(-40%);
    left: 10%;
    margin-top: 0;
    margin-left: 0;
  }
  .nav {
    padding: 4rem 1.25rem;
    gap: 1.5rem;
    justify-content: end;
  }
  .nav > a {
    font-size: 1rem;
  }
}
</style>
