import { itemsHTTP } from '@/services/http'

export default {
  state: {
    currentStep: '',
    adminCurrentStep: '',
    optionalSteps: [],
    requiredSteps: [
      { name: 'Indledning', slug: 'first-phase' },
      { name: 'Aflevering', slug: 'delivery' },
      { name: 'Anmodning overblik', slug: 'overview' },
    ],
  },
  mutations: {
    SET_CURRENT_STEP(state, payload) {
      state.currentStep = payload
    },
    SET_ADMIN_CURRENT_STEP(state, payload) {
      state.adminCurrentStep = payload
    },
    SET_OPTIONAL_STEPS(state, payload) {
      state.optionalSteps = payload
    },
    ADD_OPTIONAL_STEP(state, data) {
      state.optionalSteps.push(data)
    },
    REMOVE_STEP(state, data) {
      state.optionalSteps = state.optionalSteps.filter(step => step.id !== data)
    },
    DE_SELECT_STEP(state, payload) {
      const matchingStep = state.optionalSteps.find(step => step.slug == payload.step.slug)
      matchingStep.instances > 1 ? matchingStep.instances-- : (matchingStep.selected = false)
    },
    UPDATE_STEPS(state, data) {
      state.optionalSteps = state.optionalSteps.map(stateStep => {
        return data.find(updateStep => updateStep.id == stateStep.id) || stateStep
      })
    },
  },
  actions: {
    setCurrentStep: async ({ commit }, payload) => {
      await commit('SET_CURRENT_STEP', payload)
    },
    setAdminCurrentStep({ commit }, payload) {
      commit('SET_ADMIN_CURRENT_STEP', payload)
    },
    getOptionalSteps: async ({ commit }) => {
      const { data } = await itemsHTTP.post('get-steps')
      commit('SET_OPTIONAL_STEPS', data)
    },
    createOptionalStep: async ({ commit }, payload) => {
      const { data } = await itemsHTTP.post('create-step', payload)
      commit('ADD_OPTIONAL_STEP', data)
    },
    deleteOptionalStep: async ({ commit }, payload) => {
      await itemsHTTP.get(`delete-step?id=${payload}`)
      commit('REMOVE_STEP', payload)
    },
    deSelectedOptionalStep({ commit }, payload) {
      commit('DE_SELECT_STEP', payload)
    },
    updateOptionalStep: async ({ commit }, payload) => {
      await itemsHTTP.patch('update-steps', payload)
      commit('UPDATE_STEPS', payload)
    },
  },
  getters: {
    currentStep(state) {
      return state.currentStep
    },
    adminCurrentStep(state) {
      return state.adminCurrentStep
    },
    optionalStepNameBySlug: state => slug => {
      return state.optionalSteps.find(step => step.slug === slug)?.name
    },
    requiredStepNameBySlug: state => slug => {
      return state.requiredSteps.find(step => step.slug === slug)?.name
    },
    stepIsOptional: state => slug => {
      return state.optionalSteps.some(step => step.slug === slug)
    },
    optionalSteps(state) {
      if (state.optionalSteps) return state.optionalSteps.sort((a, b) => a.index - b.index)
    },
    stepRouteIsOptional: state => slug => {
      return state.optionalSteps.find(step => step.slug === slug)?.name
    },
    requiredSteps(state) {
      return state.requiredSteps
    },
  },
}
