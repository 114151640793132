import { itemsHTTP } from '@/services/http'

export default {
  state: {
    subSteps: null,
  },
  mutations: {
    SET_SUB_STEPS(state, payload) {
      state.subSteps = payload
    },
    ADD_SUB_STEP(state, data) {
      state.subSteps.push(data)
    },
    UPDATE_SUB_STEP(state, data) {
      let updatedStep = state.subSteps.find(subStep => subStep.id == data.id)
      updatedStep.name == data.name
    },
    REMOVE_SUB_STEP(state, data) {
      state.subSteps = state.subSteps.filter(subStep => subStep.id !== data)
    },
    UPDATE_SUB_STEPS(state, data) {
      state.subSteps = state.subSteps.map(stateSubStep => {
        return data.find(updateSubStep => updateSubStep.id == stateSubStep.id) || stateSubStep
      })
    },
  },
  actions: {
    getSubSteps: async ({ commit }) => {
      commit('SET_SUB_STEPS', null)
      return await itemsHTTP.get(`get-sub-steps/`).then(response => {
        commit('SET_SUB_STEPS', response.data)
      })
    },
    getSubStepsByStep: async ({ commit }, payload) => {
      commit('SET_SUB_STEPS', null)
      const { data } = await itemsHTTP.get(`get-sub-steps/?step=${payload}`)
      commit('SET_SUB_STEPS', data)
      return data
    },
    createSubStep: async ({ commit }, payload) => {
      const { data } = await itemsHTTP.post('create-sub-step', payload)
      commit('ADD_SUB_STEP', data)
    },
    // eslint-disable-next-line no-unused-vars
    updateSubStep: async ({ commit }, payload) => {
      const { data } = await itemsHTTP.patch('update-sub-step', payload)
      commit('UPDATE_SUB_STEP', data)
    },
    deleteSubStep: async ({ commit }, payload) => {
      await itemsHTTP.get(`delete-sub-step?id=${payload}`)
      commit('REMOVE_SUB_STEP', payload)
    },
    updateSubSteps: async ({ commit }, payload) => {
      await itemsHTTP.patch('update-sub-steps', payload)
      commit('UPDATE_SUB_STEPS', payload)
    },
  },
  getters: {
    // prettier-ignore
    currentSubSteps(state, getters, rootState) {
      const currentStep = rootState.steps.currentStep
      const currentSteps = state.subSteps.filter(subStep => subStep.parent === currentStep)
      if (state.subSteps) return currentSteps.sort((a, b) => a.index - b.index)
    },
    subSteps(state) {
      return state.subSteps.sort((a, b) => a.index - b.index)
    },
  },
}
