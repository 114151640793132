import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    name: 'projects',
    path: '/',
    component: () => import('@/views/Projects.vue'),
  },
  {
    name: 'step',
    path: '/calculator/:step/:instance?',
    component: () => import('@/views/calculator/Step.vue'),
  },
  {
    name: 'contact',
    path: '/contact',
    component: () => import('@/views/Contact.vue'),
  },
  {
    name: 'admin',
    path: '/admin',
    component: () => import('@/views/Admin.vue'),
    children: [
      {
        name: 'calculator-builder-steps',
        path: 'calculator-builder',
        component: () => import('@/views/admin/calculatorBuilder/Steps.vue'),
      },
      {
        name: 'calculator-builder-step',
        path: 'calculator-builder/:step',
        component: () => import('@/views/admin/calculatorBuilder/Step.vue'),
      },
      {
        name: 'admin-projects',
        path: 'projects',
        component: () => import('@/views/admin/Projects.vue'),
      },
      {
        name: 'admin-project',
        path: 'projects/:id',
        component: () => import('@/views/admin/Project.vue'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
