import { itemsHTTP } from '@/services/http'

export default {
  state: {
    projects: null,
    statusList: [
      { id: 'rejected', name: 'Afvist' },
      { id: 'requested', name: 'Afventer' },
      { id: 'accepted', name: 'Igangværende' },
      { id: 'done', name: 'Afsluttet' },
    ],
  },
  mutations: {
    SET_PROJECTS(state, data) {
      state.projects = data
    },
    UPDATE_PROJECT_IN_PROJECTS(state, data) {
      state.projects = state.projects.map(project => {
        if (project.id == data.id) project = data
        return project
      })
    },
    REMOVE_PROJECT(state, data) {
      state.projects = state.projects.filter(step => step.id !== data)
    },
  },
  actions: {
    getProjects: async ({ commit }) => {
      const { data } = await itemsHTTP.get('projects-api/projects')
      commit('SET_PROJECTS', data)
    },
  },
  getters: {
    projects(state) {
      return state.projects
    },
    statusNameById: state => statusId => {
      return state.statusList.find(status => status.id == statusId)?.name
    },
    statusList(state) {
      return state.statusList
    },
  },
}
