<template>
  <div class="welcome" @click="onEnter">
    <img class="logo" :src="require(`@/assets/logo.svg`)" />
    <span class="enter">Click to enter</span>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions(['onEnter']),
  },
}
</script>

<style scoped>
.welcome {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100vw - 2rem);
  height: calc(100vh - 2rem);
  background-color: #f1efee;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
  z-index: 2;
  box-sizing: border-box;
}
.logo {
  padding: 2rem;
}
.enter {
  margin-top: 2rem;
}
@media screen and (min-width: 600px) {
  .welcome {
    width: calc(100vw - 3rem);
    height: calc(100vh - 3rem);
  }
  .enter {
    margin-top: 4rem;
  }
}
</style>
