import axios from 'axios'

export default {
  state: {
    zips: [],
  },
  mutations: {
    SET_ZIPS(state, data) {
      state.zips = data
    },
  },
  actions: {
    getZips: async ({ commit }) => {
      const { data } = await axios.get('https://dawa.aws.dk/postnumre')
      const zips = data.map(zip => zip.nr)
      commit('SET_ZIPS', zips)
    },
  },
  getters: {
    zips(state) {
      return state.zips
    },
  },
}
