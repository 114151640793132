<template>
  <div id="app">
    <Welcome v-if="!hasEntered"></Welcome>
    <Navigation v-if="!isAdminRoute"></Navigation>
    <router-view />
    <vue-confirm-dialog class="confirm-dialog"></vue-confirm-dialog>
    <notifications classes="notification" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Welcome from '@/components/Welcome'
import Navigation from '@/components/Navigation.vue'

export default {
  name: 'App',
  components: {
    Welcome,
    Navigation,
  },
  methods: {
    ...mapActions(['getItems', 'getSubSteps']),
  },
  computed: {
    ...mapGetters(['hasEntered', 'isLoggedIn', 'items']),
    isAdminRoute() {
      return this.$route.path.includes('admin')
    },
  },
  mounted() {
    if (!this.item) this.getItems()
    if (!this.subSteps) this.getSubSteps()
  },
}
</script>

<style lang="scss">
@import '@/assets/shared-styles/confirm.scss';

:root {
  --color-cta: #bc9183;
  --color-cta-hover: #a3786a;

  --content-max-width: 740px;
}
body {
  margin: 0;
}
#app {
  display: flex;
  flex-direction: column;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  min-height: 100vh;
  color: #999999;
  position: relative;
}
.content-wrapper {
  max-width: var(--content-max-width);
  margin: 0 auto;
  height: 100%;
  padding: 0 1.25rem;
}
.notifications {
  width: 100% !important;
}
.notification {
  display: flex;
  text-align: center;
  font-size: 0.875rem;
  padding: 1rem;
  color: #fff;
  background: var(--color-cta);
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  border-left: 0.25rem solid var(--color-cta);
  margin: 0.5rem;
}
.notification-content {
  display: flex;
  align-items: center;
  margin-left: 0.25rem;
}
.notification-content svg {
  margin-left: 0.5rem;
}
.notification.error {
  border-left: 0.25rem solid #de7d7d;
  background: #de7d7d;
}
.notification.success {
  background: white;
  color: #333;
}
.notification-title {
  font-weight: 600;
}

// Custom login styles
#app #firebaseui-auth-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.4;
}
#app .mdl-card.firebaseui-container {
  border-radius: 0;
  box-shadow: none;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

#app .firebaseui-textfield.mdl-textfield .firebaseui-label::after {
  background-color: #333333;
}
#app .firebaseui-title {
  font-weight: 400;
}
#app .firebaseui-link {
  color: var(--color-cta);
}
#app .mdl-button {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  border-radius: 0;
  box-shadow: none;
  text-transform: none;
  font-weight: 400;
}
#app .mdl-button--raised.mdl-button--colored {
  background-color: #333333;
}
#app .mdl-button--raised.mdl-button--colored:hover {
  background-color: #111111;
}
</style>
