export default {
  state: {
    enterStatus: window.localStorage.getItem('enterStatus'),
  },
  mutations: {
    SET_ENTER_STATUS(state) {
      localStorage.setItem('enterStatus', true)
      state.enterStatus = true
    },
  },
  getters: {
    hasEntered(state) {
      return state.enterStatus
    },
  },
  actions: {
    onEnter: ({ commit }) => {
      commit('SET_ENTER_STATUS')
    },
  },
}
