import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { auth } from './services/firebase'
import VueConfirmDialog from 'vue-confirm-dialog'
import Notifications from 'vue-notification'
import VTooltip from 'v-tooltip'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.use(Notifications)
Vue.use(VTooltip)

Vue.config.productionTip = false

Sentry.init({
  Vue,
  dsn: 'https://f3287978402f47a5a3eb1d2251dd9e29@o563974.ingest.sentry.io/5704477',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
})

auth.onAuthStateChanged(user => {
  if (user === null) {
    store.dispatch('logoutUser')
    return
  } else {
    store.dispatch('updateUser', { userId: user.uid, email: user.email, displayName: user.displayName })
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
