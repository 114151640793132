import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyAzWtKLFYbdguKvfa2cmMpdPX-OrVKXb1k',
  authDomain: 'montocph-app.firebaseapp.com',
  projectId: 'montocph-app',
  storageBucket: 'montocph-app.appspot.com',
  messagingSenderId: '356918089054',
  appId: '1:356918089054:web:7083e2c4be93a973752bcf',
  measurementId: 'G-CLBSNQZES4',
}

firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()

export { firebaseConfig, auth }
