import { itemsHTTP } from '@/services/http'
import { getField, updateField } from 'vuex-map-fields'

const getDefaultState = () => {
  return {
    // Project
    project: {
      steps: [
        { slug: 'first-phase', instances: 1 },
        { slug: 'delivery', instances: 1 },
        { slug: 'overview', instances: 1 },
      ],
      firstPhase: {
        danishZip: '',
        homeType: '',
        floors: '',
        floorsPrice: 550,
        level: '',
        levelPrice: 450,
        floorCoverage: '',
        floorCoveragePrice: 350,
        trashRemoval: '',
        trashRemovalMultiplier: 1.1,
      },
      items: [],
      delivery: {
        contactName: '',
        company: '',
        address: '',
        email: '',
        phoneNumber: '',
        installationDate: '',
        installationTime: '',
      },
      images: [],
      comment: '',
      status: 'requested',
      requestDate: Date.now(),
    },
  }
}
const state = getDefaultState()

export default {
  state,
  mutations: {
    updateField,
    resetState(state) {
      sessionStorage.removeItem('vuex')
      this.dispatch('getItems', { root: true })
      this.dispatch('getOptionalSteps', { root: true })
      Object.assign(state, getDefaultState())
    },
    SET_PROJECT(state, data) {
      state.project = data
    },
    REMOVE_STEP(state, payload) {
      state.project.items = state.project.items.filter(item => item.step !== payload.step.slug && item.instance !== payload.instance)

      const matchingStep = state.project.steps.find(step => step.slug == payload.step.slug)
      matchingStep.instances > 1 ? matchingStep.instances-- : state.project.steps.filter(step => step.slug !== payload.step.slug)
    },
    SET_PROJECT_ITEMS(state, payload) {
      const newItems = payload.filter(function(payloadItem) {
        return !state.project.items.some(function(stateItem) {
          return payloadItem.id == stateItem.id
        })
      })

      newItems.forEach(newItem => {
        state.project.items.push(newItem)
      })
    },
    addImage(state, payload) {
      const image = { name: payload.name, url: payload.url }
      state.project.images.splice(payload.index, 0, image)
    },
    removeImage(state, payload) {
      state.project.images = state.project.images.filter(image => image.url !== payload)
    },
    addOptionalStep(state, payload) {
      state.project.steps.splice(1, 0, payload)
    },
    removeOptionalStep(state, payload) {
      state.project.steps = state.project.steps.filter(step => step.slug !== payload.slug)
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    createProject: async ({ commit }, payload) => {
      const { data } = await itemsHTTP.post('projects-api/project', payload)
      return data
    },
    getProject: async ({ commit }, payload) => {
      const { data } = await itemsHTTP.get(`projects-api/projects/${payload}`)
      commit('SET_PROJECT', data)
    },
    deleteProject: async ({ commit }, payload) => {
      await itemsHTTP.delete(`projects-api/projects/${payload}`)
      commit('SET_PROJECT', {})
      commit('REMOVE_PROJECT', payload, { root: true })
    },
    updateProject: async ({ commit }, payload) => {
      const { data } = await itemsHTTP.patch('projects-api/project', payload)
      commit('SET_PROJECT', data)
      commit('UPDATE_PROJECT_IN_PROJECTS', data, { root: true })
      return data
    },
    setItemsToProject({ commit }, payload) {
      commit('SET_PROJECT_ITEMS', payload)
    },
    removeStep({ commit }, payload) {
      commit('REMOVE_STEP', payload)
    },
  },
  getters: {
    getField,
    steps(state) {
      return state.project.steps
    },
    projectItems(state) {
      return state.project.items
    },
    getPrice: state => (stepName, instance) => {
      let initialPrice = 0
      const stepItems = state.project.items.filter(item => item.step == stepName && item.instance == instance)

      if (stepItems) stepItems.forEach(item => (initialPrice += item.amount * item.price))

      // Add fixed price custom items
      const fixedCustomItems = stepItems.filter(item => item.type == 'customFixed' && item.customOptionFixed)
      if (fixedCustomItems) {
        fixedCustomItems.forEach(customItem => {
          let price = parseInt(customItem.customOptionFixed.price)
          if (price) initialPrice += customItem.customOptionFixed.price
        })
      }

      // Add custom items multiplier
      const customItems = stepItems.filter(item => item.type == 'custom' && item.customOption)
      let multipliedPrice = 0

      if (customItems) {
        customItems.forEach(customItem => {
          const multiplier = customItem.customOption.multiplier / 100
          multipliedPrice += initialPrice * multiplier
        })
      }

      return Number(initialPrice + multipliedPrice).toLocaleString('da-DK', { minimumFractionDigits: 2 })
    },
    getFirstPhasePrice(state) {
      let floorsPrice = state.project.firstPhase.homeType == 'Hus' && state.project.firstPhase.floors ? state.project.firstPhase.floors * state.project.firstPhase.floorsPrice : 0
      let levelPrice = state.project.firstPhase.homeType == 'Lejlighed' && state.project.firstPhase.level.number ? state.project.firstPhase.level.number * state.project.firstPhase.levelPrice : 0
      let floorCoveragePrice = state.project.firstPhase.floorCoverage ? state.project.firstPhase.floorCoveragePrice : 0

      return floorsPrice + levelPrice + floorCoveragePrice
    },
    images(state) {
      return state.project.images
    },
    project(state) {
      return state.project
    },
  },
}
